import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./App.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircularProgressBar from "./CircularProgressBar";
function App() {
  const [page_values, setpage_values] = useState({
    total: 0,
    current: 0,
    speed: 0,
    percentage: 100,
    strip_length: 0,
    stip_difference: 0,
    current_strip_length: 529.14,
    counter: 0,
  });
  // const [counter, setcounter] = useState(0);
  const percentage = 100;
  const strip = useRef();

  useEffect(() => {
    let query = window.location.search.slice(1).split(":");
    setpage_values((prev) => ({
      ...prev,
      total: query[0],
      current: query[1],
      speed: query[2],
      percentage: query[1] / (query[0] * 0.01),
    }));
    let c = setInterval(() => {
      setpage_values((prev) => {
        let tcounter = prev.counter;
        if (prev.current - tcounter > 0) {
          tcounter = prev.counter + 1;
        }

        return {
          ...prev,
          counter: tcounter,
          total: query[0],
          current: query[1],
          percentage:
            query[1] - tcounter > 0
              ? (query[1] - tcounter) / (query[0] * 0.01)
              : 0,
        };
      });
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    setpage_values((prev) => ({
      ...prev,
      current_strip_length: 529.41,
      x: strip.current.getPointAtLength(529.41 * (100 - prev.percentage) * 0.01)
        .x,
      y: strip.current.getPointAtLength(529.41 * (100 - prev.percentage) * 0.01)
        .y,
    }));
  }, [page_values.percentage]);

  const get_time = (timeLeft) => {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = Math.floor(timeLeft % 60);
    // console.log(timeLeft, [days, hours, minutes, seconds]);
    let data = [];
    data = [
      { value: days, txt: "Day", txts: "Days" },
      { value: hours, txt: "Hr", txts: "Hrs" },
      { value: minutes, txt: "Min", txts: "Mins" },
      { value: seconds, txt: "Sec", txts: "Sec" },
    ];
    // if (days) {
    // } else if (hours) {
    //   data = [hours, minutes, seconds];
    // } else if (minutes) {
    //   data = [minutes, seconds];
    // } else if (seconds) {
    //   data = [seconds];
    // }
    return data.map((el) => ({ ...el, value: `00${el.value}`.slice(-2) }));
  };

  return (
    <div className="App">
      <div className="timer_main">
        <div className="timer_txt">
          {get_time(page_values.current - page_values.counter).map((el) => (
            <div>
              <div className="value">{el.value}</div>
              <div className="txt">{el.value > 0 ? el.txts : el.txt}</div>
            </div>
          ))}
        </div>
        <div
          className="dot"
          style={{
            "--x": page_values?.x || 0,
            "--y": page_values?.y || 0,
            transition: page_values.counter
              ? `all ${page_values.speed}s linear`
              : "",
          }}
        ></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="190"
          viewBox="0 0 190 190"
          fill="none"
        >
          <path
            d="M74.1739 4.71939C52.6793 9.97988 33.8231 23.0547 21.1645 41.476C8.50586 59.8972 2.92055 82.3904 5.4628 104.71C8.00506 127.03 18.499 147.631 34.9639 162.626C51.4287 177.622 72.7254 185.973 94.8341 186.104C116.943 186.235 138.334 178.137 154.97 163.338C171.606 148.539 182.336 128.063 185.134 105.776C187.933 83.4878 182.606 60.9298 170.16 42.3597C157.714 23.7895 139.009 10.4919 117.576 4.97681"
            stroke="#aeaeae"
            stroke-width="10"
            stroke-linejoin="round"
          />
          <path
            ref={strip}
            d="M74.1739 4.71939C52.6793 9.97988 33.8231 23.0547 21.1645 41.476C8.50586 59.8972 2.92055 82.3904 5.4628 104.71C8.00506 127.03 18.499 147.631 34.9639 162.626C51.4287 177.622 72.7254 185.973 94.8341 186.104C116.943 186.235 138.334 178.137 154.97 163.338C171.606 148.539 182.336 128.063 185.134 105.776C187.933 83.4878 182.606 60.9298 170.16 42.3597C157.714 23.7895 139.009 10.4919 117.576 4.97681"
            stroke="#05B65F"
            strokeWidth="10"
            strokeLinejoin="round"
            strokeDashoffset={
              (page_values?.current_strip_length
                ? page_values?.current_strip_length *
                  (100 - page_values.percentage) *
                  0.01
                : 0) + "px"
            }
            style={{
              transition: page_values.counter
                ? `all ${page_values.speed}s linear`
                : "",
            }}
            // stroke-dashoffset: 0px;
          />
        </svg>
      </div>
    </div>
  );
}

export default App;
